const TABLE_HEAD = ["Data", "Spektakl", "Gdzie", "Zarezerwuj"];

const TABLE_ROWS = [
  
  {
    name: "5",
    miesiac: "lutego",
    projekt: "",
    spetakl: "Matecznik",
    date: "Teatr Łątek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "7",
    miesiac: "lutego",
    projekt: "",
    spetakl: "Okienko",
    date: "Centrum Kultury  Izabelin",
    link: "",
    tekst: "",
  },
  {
    name: "7",
    miesiac: "lutego",
    projekt: "",
    spetakl: "Matecznik",
    date: "Centrum Kultury  Izabelin",
    link: "",
    tekst: "",
  },
  {
    name: "9",
    miesiac: "lutego",
    projekt: "",
    spetakl: "Dom Posterunkowa 1",
    date: "Teatr Łątek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "16",
    miesiac: "lutego",
    projekt: "",
    spetakl: "Dom Posterunkowa 1",
    date: "Teatr Łątek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },
  {
    name: "1",
    miesiac: "marca",
    projekt: "",
    spetakl: "Dom Posterunkowa 1",
    date: "Teatr Łątek Supraśl",
    link: "",
    tekst: "bezpłatnie telefonicznie",
  },

{
  name: "2",
  miesiac: "marca",
  projekt: "",
  spetakl: "Dom Posterunkowa 1",
  date: "Teatr Łątek Supraśl",
  link: "",
  tekst: "bezpłatnie telefonicznie",
},
{
  name: "9",
  miesiac: "marca",
  projekt: "",
  spetakl: "Matecznik",
  date: "Teatr Łątek Supraśl",
  link: "",
  tekst: "bezpłatnie telefonicznie",
},

];

export { TABLE_HEAD, TABLE_ROWS };
